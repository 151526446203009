import React, { useEffect, useState } from "react";
import useTitle from "../hooks/useTitle";
import { useForm } from "react-hook-form";
import { sendRpmMail, sendRpmMailOtp } from "../webservices/getway";
import { toast } from "react-toastify";

const RpmPage = () => {
  const title = useTitle()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [isOtp, setOtp] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  async function sendRpm(data) {
    setLoading(true);

    let res = await sendRpmMailOtp(data)

    if (res && res.status) {
      sessionStorage.setItem('token', res.data.otp)
      toast.success(res.message)
      setLoading(false);
      setOtp(true)
      reset()
      return;
    } else {
      setLoading(false)
    }
    return toast.error(res && res.message)
  }

  async function handleVerify(e) {
    setLoading1(true)
    e.preventDefault()
    let formDa = new FormData(e.target);
    const obj = Object.fromEntries(formDa)
    let res = await sendRpmMail(obj)
    if (res.status) {
      toast.success(res.message)
      setLoading1(false)
      setOtp(false)
      sessionStorage.removeItem('token')
    } else {
      setLoading1(false)
      toast.error(res.message)
    }
  }

  useEffect(() => {
    title("RPM")
  }, [])
  return (
    <>
      {/* Risk Profile start */}
      <div className="container py-3 text-center">
        <h3 className="position-relative d-inline-block text-primary">
          What is Risk Profile?
        </h3>
        <p className="text-center py-1 m-0">
          The <b>investment advice</b> and asset allocation for an
          <b>
            investor would have to be customized to the ability and willingness
            of the investor to assume risk
          </b>
          . This is determined by a risk profiling exercise, which seeks to
          assess the attitude towards risk and possible loss in the portfolio
          and the willingness to pursue an investment plan, after understanding
          the underlying risks.
        </p>
      </div>
      {/* Risk Profile end */}
      {/* Risk Info Cards start */}
      <div className="container-fluid py-2">
        <div className="container text-center">
          <div className="row">
            <div className="col-xl-4 col-lg-4 py-1">
              <div className="card border-0 rounded-3 shadow">
                <div className="card-body" style={{ overflow: "hidden" }}>
                  <h4 className="card-title py-3 text-primary">INFORMATION</h4>
                  <p className="card-text p-2 text-justify">
                    As SEBI registered investment adviser we obtain such
                    information from clients as it is necessary for the purpose
                    of giving investment advice. This information includes age,
                    investment objective, investment horizon, income details,
                    existing assets and liabilities and risk appetite.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-1">
              <div className="card rounded-3 border-0 shadow">
                <div className="card-body" style={{ overflow: "hidden" }}>
                  <h4 className="card-title py-3 text-primary">
                    RISK ASSESSMENT
                  </h4>
                  <p className="card-text p-2 text-justify">
                    Muhurat Investing have a process for assessing the risk that
                    a client is willing and able to take. And we inform clients
                    about their Risk profile after risk assessment.
                    <br />
                    <span className="small">
                      1. Assessing a client’s capacity towards Risk,
                      <br />
                      2. Identifying client attitude towards Risk.
                      <br />
                      3. Appropriately interpreting client responses to
                      questions and not attributing inappropriate weight to
                      certain answers.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-1">
              <div className="card rounded-3 border-0 shadow">
                <div className="card-body" style={{ overflow: "hidden" }}>
                  <h4 className="card-title py-3 text-primary">
                    RISK PROFILING TOOLS
                  </h4>
                  <p className="card-text p-2  text-justify">
                    Muhurat Investing use questionnaires to generate risk
                    appetite scores of clients. We ensure that questionnaires is
                    fit for the purpose and any limitations have been identified
                    and mitigated. Our questionnaire is neither too complex nor
                    misleading for the client to understand and express a fair
                    opinion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5">
            <p className="text-center">
              <b>
                Clients' financial risk tolerance - attitudes, values,
                motivations, preferences and experiences,
              </b>
              is measured with a risk profile. The
              <b>risk profile questionnaire</b> helps in understanding the
              <b>risk tolerance levels of a client</b>. Risk tolerance is the
              assumed level of risk that a client is willing to accept.
            </p>
          </div>
        </div>
      </div>
      {/* Risk Info Cards end */}
      {/* Risk Tolerance start */}
      <div className="container my-1 rounded bg-white shadow">
        <h4 className="text-dark text-center py-3">
          Financial Risk Tolerance Can Be Split Into Two Parts
        </h4>
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="card rounded border">
              <div className="card-body">
                <h5 className="card-title text-center">
                  1. Risk capacity: The ability to take the risk
                </h5>
                <p className="card-text pt-5">
                  This relates to the client’s financial circumstances and their
                  investment goals. Generally speaking, a client with a higher
                  level of wealth and income (relative to any liabilities they
                  have) and a longer investment term will be able to take more
                  risk, giving them a higher risk capacity.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 mt-xl-0 mt-lg-0 mt-md-5 mt-5">
            <div className="card rounded border">
              <div className="card-body">
                <h5 className="card-title text-center">
                  2. Risk attitude: the willingness to take the risk
                </h5>
                <p className="card-text pt-5">
                  Risk attitude has more to do with the individual's psychology
                  than with their financial circumstances. Some clients will
                  find the prospect of volatility in their investments and the
                  chance of losses distressing to think about. Others will be
                  more relaxed about those issues.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5">
          <p className="text-center">
            Risk tolerance is typically measured using questionnaires that
            estimate the ability and willingness to take risks. The responses of
            investors are converted into a score that may classify them under
            categories that characterize their risk preferences. Consider the
            following classification
          </p>
        </div>
      </div>
      {/* Risk Tolerance end */}
      {/* Conservative investers cards start */}
      <div className="container-fluid py-5 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 py-1">
              <div
                className="card border-0 rounded-3 shadow"
                style={{ overflow: "hidden" }}
              >
                <div className="card-body">
                  <h4 className="card-title py-2 text-dark text-center">
                    Conservative Investors
                  </h4>
                  <ul
                    className="list-group py-2"
                    style={{ fontSize: "14px !important" }}
                  >
                    <li className="list-group-item text-justify">
                      Do not like to take risk with their investments. Typically
                      new to risky instruments.
                    </li>
                    <li className="list-group-item text-justify">
                      Prefer to keep their money in the bank or in safe income
                      yielding instruments.
                    </li>
                    <li className="list-group-item text-justify">
                      May be willing to invest a small portion in risky assets
                      if it is likely to be better for the longer term.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-1">
              <div
                className="card border-0 rounded-3 shadow"
                style={{ height: "auto !important" }}
              >
                <div className="card-body">
                  <h4 className="card-title py-2 text-dark text-center">
                    Moderate Investors
                  </h4>
                  <ul
                    className="list-group py-2"
                    style={{ fontSize: "14px !important" }}
                  >
                    <li className="list-group-item text-justify">
                      May have some experience of investment, including
                      investing in risky assets such as equities.
                    </li>
                    <li className="list-group-item text-justify">
                      Understand that they have to take investment risk in order
                      to meet their long-term goals.
                    </li>
                    <li className="list-group-item text-justify">
                      Are likely to be willing to take risk with a part of their
                      available assets.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-1">
              <div
                className="card border-0 rounded-3 shadow"
                style={{ height: "auto !important" }}
              >
                <div className="card-body">
                  <h4 className="card-title py-2 text-dark text-center">
                    Aggresive Investors
                  </h4>
                  <ul
                    className="list-group py-2"
                    style={{ fontSize: "14px !important" }}
                  >
                    <li className="list-group-item text-justify">
                      Are experienced investors, who have used a range of
                      investment products in the past, and who may take an
                      active approach to managing their investments?
                    </li>
                    <li className="list-group-item text-justify">
                      Willing to take on investment risk and understand that
                      this is crucial to generating long term return.
                    </li>
                    <li className="list-group-item text-justify">
                      Willing to take risk with a significant portion of their
                      assets.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="text-center mt-2">
              The risk preferences of the investor are taken into account while
              constructing an investment portfolio.
            </p>
          </div>
        </div>
      </div>
      {/* Conservative investers cards end */}
      {/* Suitability start */}
      <div className="container my-5 mt-0 border-radius-new shadow">
        <h3 className="text-dark text-center py-2">Suitability</h3>
        <ul className="list-group">
          <li className="list-group-item active">
            As SEBI registered investment adviser we ensure the following
          </li>
          <li className="list-group-item">
            • Investment advice and related investments are appropriate to the
            client’s risk profile.
          </li>
          <li className="list-group-item">
            • Muhurat Investing Investment Advisor has a documented process for selecting products
            based on client’s investment objectives and financial situation.
          </li>
          <li className="list-group-item">
            • We always understand the nature and risks of products or assets
            selected for clients.
          </li>
          <li className="list-group-item">
            • We always have a reasonable basis for believing that a
            recommendation or transaction entered into:
          </li>
          <ul className="list-group">
            <li className="list-group-item">
              a. meets the client’s investment objectives.
            </li>
            <li className="list-group-item">
              b. is such that the client is able to bear any related investment
              risks consistent with its investment objectives and risk
              tolerance.
            </li>
            <li className="list-group-item">
              c. is such that the client has the necessary experience and
              knowledge to understand the risks involved in the transaction.
            </li>
          </ul>
        </ul>
        <div className="px-5 py-4">
          <p className="text-center">
            When advice is given to a client to purchase a complex financial
            product, such a recommendation or advice must be based upon a
            reasonable assessment that the structure and risk-reward profile of
            the financial product is consistent with clients experience,
            knowledge, investment objectives, risk appetite, and risk capacity
          </p>
        </div>
        <div className="px-5 py-4">
          <p className="text-center">
            <b className="text-danger">NOTE* :-</b>{" "}
            <b className="text-info">
              Find your questainer below. All fields are mandatory.
            </b>
          </p>
        </div>
      </div>
      {/* Suitability end */}
      {/*--------------- Form start -----------*/}
      <div className="container-fluid">
        <div className="container">
          <h2 className="text-center py-3">Risk Profiling Form</h2>
          <div className="px-3 py-1">
            <p className="text-center">
              <b className="text-danger">NOTE* :-</b>{" "}
              <b className="text-info">
                After filling the risk profile form, an OTP will be sent to your email ID.
              </b>
            </p>
          </div>
          <form id="riskForm" className="clearfix" onSubmit={handleSubmit(sendRpm)}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname"
                  id="fullname"
                  {...register("fullname", { required: "full Name is required" })}
                />
                {errors.fullname && <p className="text-danger">{errors.fullname?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Father's Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fathername"
                  id="riskfname"
                  {...register("fathername", { required: "father name is required" })}
                />
                {errors.fathername && <p className="text-danger">{errors.fathername?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Email Id</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="exampleInputEmail1"
                  {...register("email", { required: "email is required" })}
                />
                {errors.email && <p className="text-danger">{errors.email?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>PAN No</label>
                <input
                  type="text"
                  className="form-control text-uppercase"
                  name="panno"
                  id="paninput"
                  {...register("panno", { required: "pan no. is required", maxLength: 10 })}
                />
                {errors.panno && <p className="text-danger">{errors.panno?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Date Of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  id="dobinput"
                  {...register("dob", { required: "dob is required" })}
                />
                {errors.dob && <p className="text-danger">{errors.dob?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Gender</label>
                <div className="input-container">
                  <select className="form-control" name="gender" {...register("gender", { required: "gender is required" })} id="gender1">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {errors.gender && <p className="text-danger">{errors.gender?.message}</p>}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Mobile Number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="mobile"
                  id="ikycuid1"
                  pattern="\d{10}"
                  {...register("mobile", { required: "mobile NO is required" })}
                />
                {errors.mobile && <p className="text-danger">{errors.mobile?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Aadhar Number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="aadhar"
                  id="ikycuid1"
                  pattern="\d{12}"
                  {...register("aadhar", { required: "Aadhar NO is required" })}
                />
                {errors.aadhar && <p className="text-danger">{errors.aadhar?.message}</p>}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Address</label>
                <input
                  type="tel"
                  className="form-control"
                  name="address"
                  id="ikycuid1"
                  {...register("address", { required: "Address is required" })}
                />
                {errors.address && <p className="text-danger">{errors.address?.message}</p>}
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border p-4 rounded-lg">
                <label>1. What is your age group ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="agegroup"
                    className=""
                    defaultValue="18 to 35 Years9"
                    {...register("agegroup", { required: "age group is required" })}
                  />&nbsp;
                  18 to 35 Years &nbsp;
                  <input
                    type="radio"
                    name="agegroup"
                    className="ml-5"
                    defaultValue="36 to 45 Years7"
                    {...register("agegroup", { required: "age group is required" })}
                  />&nbsp;
                  36 to 45 Years &nbsp;
                  <input
                    type="radio"
                    name="agegroup"
                    className="ml-5"
                    defaultValue="46 to 55 Years5"
                    {...register("agegroup", { required: "age group is required" })}
                  />&nbsp;
                  46 to 55 Years &nbsp; <br />
                  <br />
                  <input
                    type="radio"
                    name="agegroup"
                    defaultValue="56 to 653"
                    {...register("agegroup", { required: "age group is required" })}
                  />&nbsp;
                  56 to 65 &nbsp;
                  <input
                    type="radio"
                    name="agegroup"
                    className="ml-5"
                    defaultValue="66+1"
                    {...register("agegroup", { required: "age group is required" })}
                  />&nbsp;
                  66+ &nbsp;
                  {errors.agegroup && <p className="text-danger">{errors.agegroup?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border p-4 rounded-lg">
                <label>2. What is Investment Goal ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="goal"
                    {...register("goal", { required: "Investment Goal is required" })}
                    defaultValue="Capital_Appreciation1"
                  />&nbsp;
                  Capital Appreciation &nbsp;
                  <input
                    type="radio"
                    name="goal"
                    {...register("goal", { required: "Investment Goal is required" })}
                    className="ml-5"
                    defaultValue="Regular_Income5"
                  />&nbsp;
                  Regular Income &nbsp;
                  <br />
                  <br />
                  <input
                    type="radio"
                    name="goal"
                    {...register("goal", { required: "Investment Goal is required" })}
                    className=""
                    defaultValue="both3"
                  />&nbsp;
                  Capital Appreciation and Regular Income &nbsp;
                  {errors.goal && <p className="text-danger">{errors.goal?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>3. What is your Proposed Investment Amount ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="investment_amount"
                    {...register("investment_amount", { required: "Investment Amount is required" })}
                    className=""
                    defaultValue="50000 to 1 lacs1"
                  />&nbsp;
                  50000 to 1 lacs &nbsp;
                  <input
                    type="radio"
                    name="investment_amount"
                    {...register("investment_amount", { required: "Investment Amount is required" })}
                    className="ml-5"
                    defaultValue="1 to 2 lacs3"
                  />&nbsp;
                  1 to 2 lacs &nbsp;
                  <input
                    type="radio"
                    name="investment_amount"
                    {...register("investment_amount", { required: "Investment Amount is required" })}
                    className="ml-5"
                    defaultValue="2-5 lacs5"
                  />&nbsp;
                  2-5 lacs &nbsp;
                  <br />
                  <br />
                  <input
                    type="radio"
                    name="investment_amount"
                    {...register("investment_amount", { required: "Investment Amount is required" })}
                    className=""
                    defaultValue="5-10 lacs7"
                  />&nbsp;
                  5-10 lacs &nbsp;
                  <input
                    type="radio"
                    name="investment_amount"
                    {...register("investment_amount", { required: "Investment Amount is required" })}
                    className="ml-5"
                    defaultValue="> 10 lacs9"
                  />&nbsp;
                  &gt; 10 lacs &nbsp;
                  {errors.investment_amount && <p className="text-danger">{errors.investment_amount?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>4. What is your Preferred Investment type ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="investment_type"
                    {...register("investment_type", { required: "Preferred Investment type is required" })}
                    className=""
                    defaultValue="Long_term1"
                  />&nbsp;
                  Long term Holdings &nbsp;
                  <input
                    type="radio"
                    name="investment_type"
                    {...register("investment_type", { required: "Preferred Investment type is required" })}
                    className="ml-5"
                    defaultValue="Short_term3"
                  />&nbsp;
                  Short term Holdings &nbsp;
                  <br />
                  <br />
                  <input
                    type="radio"
                    name="investment_type"
                    {...register("investment_type", { required: "Preferred Investment type is required" })}
                    className=""
                    defaultValue="Intraday5"
                  />&nbsp;
                  Intraday/Overnight holdings &nbsp;
                  <input
                    type="radio"
                    name="investment_type"
                    {...register("investment_type", { required: "Preferred Investment type is required" })}
                    className="ml-5"
                    defaultValue="Positional/Derivatives0"
                  />&nbsp;
                  Positional/Derivatives &nbsp;
                  {errors.investment_type && <p className="text-danger">{errors.investment_type?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>5. What is yours Yearly Income Range ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="income_range"
                    {...register("income_range", { required: "Income Range is required" })}
                    className=""
                    defaultValue="50000_To_1_lacs1"
                  />&nbsp;
                  Below 1 lac &nbsp;
                  <input
                    type="radio"
                    name="income_range"
                    {...register("income_range", { required: "Income Range is required" })}
                    className="ml-5"
                    defaultValue="1-3_lacs3"
                  />&nbsp;
                  1-3 lac &nbsp;
                  <input
                    type="radio"
                    name="income_range"
                    {...register("income_range", { required: "Income Range is required" })}
                    className="ml-5"
                    defaultValue="3-5_lacs5"
                  />&nbsp;
                  3-6 lac &nbsp;
                  <input
                    type="radio"
                    name="income_range"
                    {...register("income_range", { required: "Income Range is required" })}
                    className="ml-5"
                    defaultValue="Above_5_lacs7"
                  />&nbsp;
                  6-10 lac &nbsp;
                  <br />
                  <br />
                  <input
                    type="radio"
                    name="income_range"
                    {...register("income_range", { required: "Income Range is required" })}
                    className=""
                    defaultValue="25_lac9"
                  />&nbsp;
                  &gt;10 lac above &nbsp;
                  {errors.income_range && <p className="text-danger">{errors.income_range?.message}</p>}
                </div>
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4">
                <h6 className="text-center">
                  6. Occupation (Please select the appropriate)
                </h6>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="occupation"
                    {...register("occupation", { required: "Occupation is required" })}
                    className=""
                    defaultValue="Private_sector_service"
                  />&nbsp;
                  Private sector service &nbsp;
                  <input
                    type="radio"
                    name="occupation"
                    {...register("occupation", { required: "Occupation is required" })}
                    className="ml-5"
                    defaultValue="Public_sector"
                  />&nbsp;
                  Public sector/Government sector &nbsp;
                  <input
                    type="radio"
                    name="occupation"
                    {...register("occupation", { required: "Occupation is required" })}
                    className="ml-5"
                    defaultValue="Business"
                  />&nbsp;
                  Business &nbsp;
                  <input
                    type="radio"
                    name="occupation"
                    {...register("occupation", { required: "Occupation is required" })}
                    className="ml-5"
                    defaultValue="Retired/Housewife,/Student "
                  />&nbsp;
                  Retired/Housewife,/Student &nbsp;
                  <input
                    type="radio"
                    name="occupation"
                    {...register("occupation", { required: "Occupation is required" })}
                    className="ml-5"
                    defaultValue="Professional"
                  />&nbsp;
                  Professional /Dealer /Self Employed &nbsp;
                  <input
                    type="radio"
                    name="occupation"
                    {...register("occupation", { required: "Occupation is required" })}
                    className="ml-5"
                    defaultValue="Others"
                  />&nbsp;
                  Others &nbsp;
                  {errors.occupation && <p className="text-danger">{errors.occupation?.message}</p>}
                </div>
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4">
                <h4 className="text-center mb-5">
                  <strong>Sources of Income</strong>
                </h4>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 border rounded-lg p-4">
                    <label>7. Primary Source</label>
                    <div className="mt-4">
                      <input
                        type="radio"
                        name="sources_of_income"
                        {...register("sources_of_income", { required: "Primary Source is required" })}
                        className=""
                        defaultValue="Salary"
                      />&nbsp;
                      Salary &nbsp;
                      <input
                        type="radio"
                        name="sources_of_income"
                        {...register("sources_of_income", { required: "Primary Source is required" })}
                        className="ml-5"
                        defaultValue="Business"
                      />&nbsp;
                      Business &nbsp;
                      {errors.sources_of_income && <p className="text-danger">{errors.sources_of_income?.message}</p>}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 border rounded-lg p-4">
                    <label>8. Secondary Source</label>
                    <div className="mt-4">
                      <input
                        type="radio"
                        name="secondary_source"
                        {...register("secondary_source")}
                        className=""
                        defaultValue="Royalties"
                      />&nbsp;
                      Royalties &nbsp;
                      <input
                        type="radio"
                        name="secondary_source"
                        {...register("secondary_source")}
                        className="ml-5"
                        defaultValue="Rental"
                      />&nbsp;
                      Rental &nbsp;
                      <input
                        type="radio"
                        name="secondary_source"
                        {...register("secondary_source")}
                        className="ml-5"
                        defaultValue="Dividend"
                      />&nbsp;
                      Dividend &nbsp;
                      <input
                        type="radio"
                        name="secondary_source"
                        {...register("secondary_source")}
                        className="ml-5"
                        defaultValue="Others"
                      />&nbsp;
                      None &nbsp;
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  9. Approx Value of assets held like property, FD, Shares,
                  Mutual Fund etc?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="value_of_assets"
                    {...register("value_of_assets", { required: "Approx Value of assets is required" })}
                    className=""
                    defaultValue="1_lacs1"
                  />&nbsp;
                  &lt;1 lacs &nbsp;
                  <input
                    type="radio"
                    name="value_of_assets"
                    {...register("value_of_assets", { required: "Approx Value of assets is required" })}
                    className="ml-5"
                    defaultValue="1-2_lacs3"
                  />&nbsp;
                  1-2 lacs &nbsp;
                  <input
                    type="radio"
                    name="value_of_assets"
                    {...register("value_of_assets", { required: "Approx Value of assets is required" })}
                    className="ml-5"
                    defaultValue="2-5_lacs5"
                  />&nbsp;
                  2-5 lacs &nbsp;
                  <input
                    type="radio"
                    name="value_of_assets"
                    {...register("value_of_assets", { required: "Approx Value of assets is required" })}
                    className="ml-5"
                    defaultValue="5_lacs7"
                  />&nbsp;
                  5-10 lacs &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="value_of_assets"
                    {...register("value_of_assets", { required: "Approx Value of assets is required" })}
                    className=""
                    defaultValue="10_lacs9"
                  />&nbsp;
                  5-10 lacs &nbsp;
                  {errors.value_of_assets && <p className="text-danger">{errors.value_of_assets?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>10. What is your Investment Experience?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="investment_experience"
                    {...register("investment_experience", { required: "Investment Experience is required" })}
                    className=""
                    defaultValue="3_year1"
                  />&nbsp;
                  3 years &nbsp;
                  <input
                    type="radio"
                    name="investment_experience"
                    {...register("investment_experience", { required: "Investment Experience is required" })}
                    className=""
                    defaultValue="3-5_year3"
                  />&nbsp;
                  3-5 years &nbsp;
                  <input
                    type="radio"
                    name="investment_experience"
                    {...register("investment_experience", { required: "Investment Experience is required" })}
                    className="ml-5"
                    defaultValue="6-10_years5"
                  />&nbsp;
                  6-10 years &nbsp;
                  <input
                    type="radio"
                    name="investment_experience"
                    {...register("investment_experience", { required: "Investment Experience is required" })}
                    className="ml-5"
                    defaultValue="10+_years7"
                  />&nbsp;
                  10 years+ &nbsp;
                  {errors.investment_experience && <p className="text-danger">{errors.investment_experience?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  11. What is the option best describe your current stage of
                  life ?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="current_stage"
                    {...register("current_stage", { required: "current stage is required" })}
                    className=""
                    defaultValue="Single with few financial burdens"
                  />&nbsp;
                  Single with few financial burdens &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="current_stage"
                    {...register("current_stage", { required: "current stage is required" })}
                    className=""
                    defaultValue="A couple without children preparing for future
                  responsibilities"
                  />&nbsp;
                  A couple without children preparing for future
                  responsibilities. &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="current_stage"
                    {...register("current_stage", { required: "current stage is required" })}
                    className=""
                    defaultValue="you are in a peak earning years"
                  />&nbsp;
                  You are in a peak earning years and both are working and
                  secured by a lien on the property and debts are under control &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="current_stage"
                    {...register("current_stage", { required: "current stage is required" })}
                    className=""
                    defaultValue="Preparing For retirement for future goal"
                  />&nbsp;
                  Preparing For retirement for future goal &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="current_stage"
                    {...register("current_stage", { required: "current stage is required" })}
                    className=""
                    defaultValue="Retired"
                  />&nbsp;
                  Retired , already receiving a government pension. &nbsp;
                  {errors.current_stage && <p className="text-danger">{errors.current_stage?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  12. Count of financially dependents person on you?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="financially_dependents"
                    {...register("financially_dependents", { required: "financially dependents person is required" })}
                    className=""
                    defaultValue="None5"
                  />&nbsp;
                  None &nbsp;
                  <input
                    type="radio"
                    name="financially_dependents"
                    {...register("financially_dependents", { required: "financially dependents person is required" })}
                    className="ml-5"
                    defaultValue="1-33"
                  />&nbsp;
                  Between 1-3 &nbsp;
                  <input
                    type="radio"
                    name="financially_dependents"
                    {...register("financially_dependents", { required: "financially dependents person is required" })}
                    className="ml-5"
                    defaultValue="4+1"
                  />&nbsp;
                  Above 4 Members &nbsp;
                  {errors.financially_dependents && <p className="text-danger">{errors.financially_dependents?.message}</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  13. I would start to worry about my investments if my
                  Investment value falls 50%
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="investment_value"
                    {...register("investment_value", { required: "This Question is required" })}
                    className=""
                    defaultValue="Keep investments as it is0"
                  />&nbsp;
                  Keep investments as it is &nbsp;
                  <input
                    type="radio"
                    name="investment_value"
                    {...register("investment_value", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Sell and Save cash3"
                  />&nbsp;
                  Sell and Save cash &nbsp;
                  <input
                    type="radio"
                    name="investment_value"
                    {...register("investment_value", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Wait till the market recovers and then sell5"
                  />&nbsp;
                  Wait till the market recovers and then sell &nbsp;
                  <input
                    type="radio"
                    name="investment_value"
                    {...register("investment_value", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="I can add investment (money) if require7"
                  />&nbsp;
                  I can add investment (money) if require &nbsp;
                  {errors.investment_value && <p className="text-danger">This Question is required</p>}
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  14. What is your expected rate of return from your
                  investments?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="expected_rate_of_return"
                    {...register("expected_rate_of_return", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="Potential return of 6-10 % of investment"
                  />&nbsp;
                  {`Potential return of 6-10 % of investment`} &nbsp;
                  <input
                    type="radio"
                    name="expected_rate_of_return"
                    {...register("expected_rate_of_return", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Potential return of 10-15 % of investment"
                  />&nbsp;
                  Potential return of 10-15 % of investment &nbsp;
                  <input
                    type="radio"
                    name="expected_rate_of_return"
                    {...register("expected_rate_of_return", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Potential return of 15-20% of investment"
                  />&nbsp;
                  Potential return of 15-20% of investment &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="expected_rate_of_return"
                    {...register("expected_rate_of_return", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="Potential return of more than 25% of investment"
                  />&nbsp;
                  Potential return of more than 25% of investment &nbsp;
                  {errors.expected_rate_of_return && <p className="text-danger">This Question is required</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  15. What is your experience with investments in past?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="investments_in_past"
                    {...register("investments_in_past", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="Very_Good7"
                  />&nbsp;
                  Very Good &nbsp;
                  <input
                    type="radio"
                    name="investments_in_past"
                    {...register("investments_in_past", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Good5"
                  />&nbsp;
                  Good &nbsp;
                  <input
                    type="radio"
                    name="investments_in_past"
                    {...register("investments_in_past", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Moderate3"
                  />&nbsp;
                  Moderate &nbsp;
                  <input
                    type="radio"
                    name="investments_in_past"
                    {...register("investments_in_past", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Bad1"
                  />&nbsp;
                  Bad &nbsp;
                  <br />
                  <br />
                  <input
                    type="radio"
                    name="investments_in_past"
                    {...register("investments_in_past", { required: "secondary_source is required" })}
                    className="radio"
                    defaultValue="very_Bad0"
                  />&nbsp;
                  Very Bad &nbsp;
                  {errors.investments_in_past && <p className="text-danger">This Question is required</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  16. In order to achieve high returns I am willing to choose
                  high risk investments?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="high_risk_investments"
                    {...register("high_risk_investments", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="Strongly_prefer7"
                  />&nbsp;
                  Strongly prefer &nbsp;
                  <input
                    type="radio"
                    name="high_risk_investments"
                    {...register("high_risk_investments", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Prefer5"
                  />&nbsp;
                  Prefer &nbsp;
                  <input
                    type="radio"
                    name="high_risk_investments"
                    {...register("high_risk_investments", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Neutral3"
                  />&nbsp;
                  Neutral &nbsp;
                  <br />
                  <br />
                  <input
                    type="radio"
                    name="high_risk_investments"
                    {...register("high_risk_investments", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="Do_not_prefer1"
                  />&nbsp;
                  Do not prefer &nbsp;
                  <input
                    type="radio"
                    name="high_risk_investments"
                    {...register("high_risk_investments", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Strongly_Do_not_prefer0"
                  />&nbsp;
                  Strongly do not prefer &nbsp;
                  {errors.high_risk_investments && <p className="text-danger">This Question is required</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  17. what percentage of monthly income is allocated to pay off
                  debt (all EMIs)?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    name="percentage_of_monthly_income"
                    {...register("percentage_of_monthly_income", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="None7"
                  />&nbsp;
                  None &nbsp;
                  <input
                    type="radio"
                    name="percentage_of_monthly_income"
                    {...register("percentage_of_monthly_income", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Less than 15%5"
                  />&nbsp;
                  Less than 15% &nbsp;
                  <input
                    type="radio"
                    name="percentage_of_monthly_income"
                    {...register("percentage_of_monthly_income", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue="Between 15% to 353"
                  />&nbsp;
                  Between 15% to 35 &nbsp;
                  <br />
                  <input
                    type="radio"
                    name="percentage_of_monthly_income"
                    {...register("percentage_of_monthly_income", { required: "secondary_source is required" })}
                    className=""
                    defaultValue="Between 35% to 501"
                  />&nbsp;
                  Between 35% to 50 &nbsp;
                  <input
                    type="radio"
                    name="percentage_of_monthly_income"
                    {...register("percentage_of_monthly_income", { required: "secondary_source is required" })}
                    className="ml-5"
                    defaultValue=">500"
                  />&nbsp;
                  &gt;50 &nbsp;
                  {errors.percentage_of_monthly_income && <p className="text-danger">This Question is required</p>}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  18. Please Selects, if applicable, for any of your authorized
                  signatories/ Promoters/ Partners/ Karta/ Trustees/ whole time
                  directors
                </label>
                <select name="directly" className="form-control mt-3"
                  {...register("directly", { required: "This Field is required" })}>
                  <option value="">-Select-</option>
                  <option value="NA">NA</option>
                  <option value="Civil Servant">Civil Servant</option>
                  <option value="Politician">Politician</option>
                  <option value="Current or former head of state">
                    Current or former head of state
                  </option>
                  <option value="Bureaucrat (Tax authorities, Foreign Services, IAS etc)">
                    Bureaucrat (Tax authorities, Foreign Services, IAS etc)
                  </option>
                  <option value="Current or former MP/MLA/MLC">
                    Current or former MP/MLA/MLC
                  </option>
                  <option value="Connected to any company/promoter group/ group of companies listed on any stock exchange">
                    Connected to any company/promoter group/ group of companies
                    listed on any stock exchange
                  </option>
                </select>
                {errors.directly && <p className="text-danger">{errors.directly?.message}</p>}
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4">
                <div className="mb-4 p-2">
                  <input
                    name="check"
                    className=""
                    type="checkbox"
                    defaultChecked={false}
                  />&nbsp;&nbsp;
                  I hereby declare that I have read, understood and personally accomplished this entire Risk Profiling Questionnaire and that the answers I have given are accurate. I understand the risk involved in investing in equities. I will exercise my own independent judgmen in subscribing the suitable package/s (if any) as per my Risk Profile Score. You may review your answers before they are recorded in the system. Once recorded they cannot be changed. This is done to ensure the integrity of the data. You can review your answers by scrolling through the questionnaire. Now is the best time to correct any mistakes or omissions.
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-dark buttons mt-5 px-5 py-2"
                    disabled={isOtp}
                  > {loading ? <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> : "Submit"}</button>
                </div>
              </div>
            </div>
          </form>
          {isOtp ? <div className="container">
            <div className="row justify-content-center">
              <div className="col-3">
                <form onSubmit={handleVerify}>
                  <div className="col-md-12 mt-4">
                    <input
                      name="otp"
                      type="Tel"
                      placeholder="Enter OTP"
                      className='form-control'
                      minLength={6}
                      maxLength={6}
                      pattern='\d{6}'
                      required
                    />
                  </div>
                  <div className="col-md-12 mt-4">
                    <button className="btn btn-dark buttons px-5 py-2" >
                      {loading1 ? <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div> : "Verify OTP"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div> : null}
          <div>
            <p className="text-center p-5">
              <b className="text-danger">Note* : </b>Client’s with long term
              investment goal or capital appreciation as investment objectives
              are not accepted at The Muhurat-Investing Private Limited.
            </p>
          </div>
        </div>
      </div>
      {/*--------------- Form end -------------*/}
      {/* Analysis start */}
      <div className="container my-5 mt-0">
        <h4 className="text-center">Analysis</h4>
        <p className="mt-3 text-center">
          In setting up an investment portfolio suitable for you, your financial
          adviser will ask you a series of questions about your financial and
          lifestyle goals. Using this information, plus details of your current
          assets, liabilities and income, your adviser can determine what level
          of risk or exposure you are prepared to tolerate in relation to
          fluctuations in the marketplace - and the level that makes sense for
          your stage in life. From this, an appropriate mix of assets can be
          allocated to your investment portfolio.
        </p>
      </div>
      {/* Analysis end */}
    </>
  );
};

export default RpmPage;
