import React, { useEffect, useState } from "react";
import * as Component from "../components/ComponentRoute";
import { Link } from "react-router-dom";
import { RoutePath } from "../RoutePath";
import useTitle from "../hooks/useTitle";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { sendMail } from "../webservices/getway";


const Home = () => {

  const [loading, setLoading] = useState(false)
  const title = useTitle()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    title("Home")
  }, [])

  const sendEmail = async (data) => {
    setLoading(true);

    let res = await sendMail(data)

    if (res && res.status) {
      toast.success(res.message)
      setLoading(false);
      return;
    }

    console.log(res);

    setLoading(false)
    reset()
    return toast.error(res && res.message)
  };

  return (
    <>
      {/* Hero section Start */}
      <div className="container-fluid p-0 mb-3" style={{ backgroundImage: `url(${"'"}${window.location.origin}/img/stock-bull-market-design.webp${"'"})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className="container p-3">
          <div className="row justify-content-evenly">
            <div className="col-lg-6 col-md-6 col-12 mt-5">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-3 mt-5" style={{ maxWidth: 900 }}>
                  <h4 className="mb-3 text-light">Muhurat-Investing</h4>
                  <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                    achive your financial goal with sebi registered investment advisor
                  </h5>
                  {/* <h4 className="text-dark mb-md-4 animated zoomIn">
                    You must value the business in order to value the stock
                  </h4> */}

                  <h5 className="text-light">by using equity & government securities</h5>
                  <div className="mt-5">
                    <Link
                      to={RoutePath.CONTACT}
                      className="btn btn-primary py-md-3 px-md-5 buttons animated slideInRight"
                    >
                      Contact Us
                    </Link>
                    <Link
                      to="https://api.whatsapp.com/send?phone=919584096836"
                      className="btn btn-success ms-4 py-md-3 px-md-5 buttons animated slideInRight"
                    >
                      whatsapp Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-12 mt-0 wow slideInUp"
              data-wow-delay="0.3s"
            >
              <div className="row justify-content-center align-items-center">
                <form onSubmit={handleSubmit(sendEmail)}>
                  <div className="col-12 wow zoomIn mt-4" data-wow-delay="0.3s">
                    <div className="d-flex flex-column p-5 rounded-3" style={{ backdropFilter: "blur(10px)" }}>
                      <h3 className="text-light mb-3 text-center">Register Now</h3>
                      <div
                        className="date mb-3"
                        id="date"
                        data-target-input="nearest"
                      >
                        <input
                          type="text"
                          className="form-control border-0"
                          placeholder="Full Name"
                          style={{ height: "40px" }}
                          name="fullname"
                          {...register('fullname', { required: "full name is required" })}
                        />
                        {errors.fullname && <p className="text-danger">fullname is required.</p>}
                        <input
                          type="text"
                          className="form-control border-0 mt-4"
                          placeholder="Email Address"
                          style={{ height: "40px" }}
                          name="email"
                          {...register('email', { required: "email is required" })}
                        />
                        {errors.email && <p className="text-danger">email is required.</p>}
                        <input
                          type="text"
                          className="form-control border-0 mt-4"
                          placeholder="Mobile Number"
                          style={{ height: "40px" }}
                          name="number"
                          {...register('number', { required: "number is required" })}
                        />
                        {errors.number && <p className="text-danger">Mob number is required.</p>}
                        <select
                          type="text"
                          className="form-control border-0 mt-4 bg-white"
                          style={{ height: "40px" }}
                          name="service"
                          {...register('service', { required: "service is required" })}
                        >
                          <option value="">-Services-</option>
                          <option value="Stock cash">Stock cash</option>
                          <option value="HNI Cash">HNI Cash</option>
                          <option value="Stock Future">Stock Future</option>
                          <option value="HNI Future">HNI Future</option>
                          <option value="Stock Option">Stock Option</option>
                          <option value="HNI option">HNI option</option>
                          <option value="Index Future">Index Future</option>
                          <option value="Index Option">Index Option</option>
                          <option value="BTST">BTST</option>
                          <option value="Short term investment">Short term investment</option>
                          <option value="Long term investment">Long term investment</option>

                        </select>
                        {errors.service && <p className="text-danger">service is required.</p>}
                      </div>

                      <div className="custom-control custom-checkbox pt-2">
                        <input
                          className="form-control-input"
                          id="customCheck1"
                          type="checkbox"
                          defaultValue={1}
                          name="checkbox"
                          defaultChecked="checked"
                        />&nbsp;
                        <label className="text-light" htmlFor="customCheck1">
                          I agree to all &nbsp;
                          <Link to={RoutePath.DISCLAIMER} className="text-dark">
                            Terms &amp; Conditions
                          </Link>
                        </label>
                        <label className="text-dark mt-2 font-weight-bold text-capitalize"><span className="text-danger" style={{ fontSize: "20px" }}>*</span>Investments in securities market are subject to market risks. Read all the related documents carefully before investing.</label>
                        <label className="text-dark mt-2 mb-2 font-weight-bold text-capitalize"><span className="text-danger" style={{ fontSize: "20px" }}>*</span>Registration granted by SEBI and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.</label>
                      </div>
                      <button type="submit" className="btn btn-dark buttons">
                        {loading ? <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div> : "Submit"}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero section End */}

      <Component.ABOUT_COMPONENT />
      <Component.MISSION_COMPONENT />
      <Component.SERVICES_COMPONENT />
      {/* <Component.TESTROMONIAL_COMPONENT /> */}
      <section className="container-fluid wow fadeInUp" data-wow-delay="0.1s" >
        <div className="row">
          <div className="col-12">
            <h4 className="text-center">-Why You Choose-</h4>
            <div className="row p-5">
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/expertize.jpeg" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Expertise</h3>
                  <p className="text-success p-4">Benefit from my extensive experience in the stock market and financial advisory.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Customized Solutions.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Customized Solutions</h3>
                  <p className="text-success p-4">Receive personalized investment strategies tailored to your financial goals and risk tolerance</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Market Insights.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Market Insights</h3>
                  <p className="text-success p-4">Gain access to timely market analysis and insights to make informed investment decisions.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Risk Management.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Risk Management</h3>
                  <p className="text-success p-4">Our experts employ advanced risk management techniques to protect your investments.</p>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why shadow p-3">
                <div className="d-flex justify-content-center"> 
                  <img src="img/Performance Track Record.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Performance Track Record</h3>
                  <p className="text-success p-4">We have a proven track record of delivering impressive returns for our clients. </p>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Transparency.jpeg" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Transparency</h3>
                  <p className="text-success p-4">Experience transparency in our processes and recommendations, ensuring you understand every step</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Customer Support.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Customer Support</h3>
                  <p className="text-success p-4">Our dedicated customer support team is always available to assist you with any queries or concerns.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Ethical Practices.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Ethical Practices</h3>
                  <p className="text-success p-4">We adhere to strict ethical standards, ensuring the highest level of integrity in our services.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 p-3">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Continuous Monitoring.png" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Continuous Monitoring</h3>
                  <p className="text-success p-4">Benefit from continuous monitoring of your investments to adapt to changing market conditions.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="card-why p-3">
                  <div className="d-flex justify-content-center">
                    <img src="img/Client-Centric Approach.jpeg" alt="img2" height={100} width={100} />
                  </div>
                  <h3 className="text-center">Client-Centric Approach</h3>
                  <p className="text-success p-4">Your satisfaction is our priority, and we strive to exceed your expectations in every interaction</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Component.TEAM_COMPONENT /> */}
    </>
  );
};

export default Home;
