import React, { useEffect } from 'react'
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from '../hooks/useTitle'
export default function SupportPage() {
    const title = useTitle()

    useEffect(() => {
        title("Support")
    }, [])

    const faqs = [
        {
            question: "What is Muhurat Investing?",
            answer: "Muhurat Investing is a platform that helps investors make informed decisions based on market analysis and trends."
        },
        {
            question: "How can I contact support?",
            answer: "You can reach our support team via email at support@muhuratinvesting.com or through our contact page."
        },
        {
            question: "What support options are available?",
            answer: "We offer a comprehensive FAQ section, email support, and a contact form for inquiries. For premium users, we provide priority support."
        },
        {
            question: "Do you have live chat support?",
            answer: "Currently, we do not offer live chat support, but we are working on implementing it in the future. You can reach us via email for assistance."
        }
    ];

    return (
        <>
            <COMPONENT.HERO_COMPONENT name={"Support"} bg={"bg-offer"} />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 bg-white shadow-lg p-5 mb-3'>
                        <div className="section-title mb-4 zoomIn wow">
                            <h5 className="position-relative d-inline-block text-dark text-uppercase">
                               For Any Support
                            </h5>

                            <div className='row mt-4 mb-3'>
                                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                    <div className=' p-3 border border-2 border-primary'>
                                        <h4 className='text-center bg-primary text-light p-1'>Customer Care</h4>
                                        <hr />
                                        <p className='ps-5'>
                                            <i className="bi bi-person-fill text-primary me-2" />
                                            <b className='text-dark'>Contact Person</b>:- Vishal Borasi
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-geo-alt text-primary me-2" />
                                            <b className='text-dark'>Address</b>:- 13-14/07 Mukharji Nagar Indore
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-telephone text-primary me-2" />
                                            <b className='text-dark'>Contact Number</b>:- 9584096836
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-envelope-open text-primary me-2" />
                                            <b className='text-dark'>Email</b>:- info@muhuratinvesting.com
                                        </p>
                                        <p className='ps-5'>
                                            <i class="bi bi-clock text-primary me-2"></i>
                                            <b className='text-dark'>Working Hours</b>:- 9AM To 5PM
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                    <div className='p-3 border border-2 border-primary'>
                                        <h4 className='text-center bg-primary text-light p-1'>Head Of Customer Care</h4>
                                        <hr />
                                        <p className='ps-5'>
                                            <i className="bi bi-person-fill text-primary me-2" />
                                            <b className='text-dark'>Contact Person</b>:- Vishal Borasi
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-geo-alt text-primary me-2" />
                                            <b className='text-dark'>Address</b>:- 13-14/07 Mukharji Nagar Indore
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-telephone text-primary me-2" />
                                            <b className='text-dark'>Contact Number</b>:- 9584096836
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-envelope-open text-primary me-2" />
                                            <b className='text-dark'>Email</b>:- support@muhuratinvesting.com
                                        </p>
                                        <p className='ps-5'>
                                            <i class="bi bi-clock text-primary me-2"></i>
                                            <b className='text-dark'>Working Hours</b>:- 9AM To 5PM
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                    <div className='p-3 border border-2 border-primary'>
                                        <h4 className='text-center bg-primary text-light p-1'>Compliance Officer</h4>
                                        <hr />
                                        <p className='ps-5'>
                                            <i className="bi bi-person-fill text-primary me-2" />
                                            <b className='text-dark'>Contact Person</b>:- Vishal Borasi
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-geo-alt text-primary me-2" />
                                            <b className='text-dark'>Address</b>:- 13-14/07 Mukharji Nagar Indore
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-telephone text-primary me-2" />
                                            <b className='text-dark'>Contact Number</b>:- 9584096836
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-envelope-open text-primary me-2" />
                                            <b className='text-dark'>Email</b>:- support@muhuratinvesting.com
                                        </p>
                                        <p className='ps-5'>
                                            <i class="bi bi-clock text-primary me-2"></i>
                                            <b className='text-dark'>Working Hours</b>:- 9AM To 5PM
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                    <div className='p-3 border border-2 border-primary'>
                                        <h4 className='text-center bg-primary text-light p-1'>Principal Officer</h4>
                                        <hr />
                                        <p className='ps-5'>
                                            <i className="bi bi-person-fill text-primary me-2" />
                                            <b className='text-dark'>Contact Person</b>:- Vishal Borasi
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-geo-alt text-primary me-2" />
                                            <b className='text-dark'>Address</b>:- 13-14/07 Mukharji Nagar Indore
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-telephone text-primary me-2" />
                                            <b className='text-dark'>Contact Number</b>:- 9584096836
                                        </p>
                                        <p className='ps-5'>
                                            <i className="bi bi-envelope-open text-primary me-2" />
                                            <b className='text-dark'>Email</b>:- vishuborasi@gmail.com
                                        </p>
                                        <p className='ps-5'>
                                            <i class="bi bi-clock text-primary me-2"></i>
                                            <b className='text-dark'>Working Hours</b>:- 9AM To 5PM
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h4 className='mt-2'>FAQs</h4>
                            <ul type="square" className='mt-2'>
                                {faqs && faqs.map((item, idx) => (
                                    <li key={idx}>
                                        <strong>Question &nbsp;{idx + 1}. &nbsp; {item.question}</strong>
                                        <br />
                                        <p>Answer : &nbsp; {item.answer}</p>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-6 text-center">
                                <p>Still need help? Contact us at <a href="mailto:support@muhuratinvesting.com" className="text-blue-600">support@muhuratinvesting.com</a>.</p>
                            </div>
                            <div className='row justify-content-center mt-5'>
                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="bi bi-geo-alt fs-1 text-primary me-3" />
                                        <div className="text-start">
                                            <h4 className="mb-0">Our Office</h4>
                                            <span>13,14- Mukharji Nagar Indore 452015 M.P.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="bi bi-envelope-open fs-1 text-primary me-3" />
                                        <div className="text-start">
                                            <h4 className="mb-0">Email Us</h4>
                                            <a href="mailto:support@muhuratinvesting.com">
                                                <span>support@muhuratinvesting.com</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                    <div className="d-flex align-items-center">
                                        <i className="bi bi-phone-vibrate fs-1 text-primary me-3" />
                                        <div className="text-start">
                                            <h4 className="mb-0">Call Us</h4>
                                            <a href="tel:9584096836">
                                                <span>+919584096836</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
