import { Urls } from "./urls";

export async function sendMail(data) {
    try {
        let res = await fetch(Urls.SEND_ENQUIRY_EMAIL, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Accept": "*"
            },
            body: JSON.stringify(data)
        })
        res = await res.json()
        return res;
    } catch (err) {
        console.log(err);
    }
}

export async function sendComplaintMail(data) {
    try {
        let res = await fetch(Urls.SEND_COMPLAINT_EMAIL, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Accept": "*"
            },
            body: JSON.stringify(data)
        })
        res = await res.json()
        return res;
    } catch (err) {
        console.log(err);
    }
}

export async function sendRpmMailOtp(data) {
    try {
        let res = await fetch(Urls.SEND_RPM_EMAIL_OTP, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Accept": "*"
            },
            body: JSON.stringify(data)
        })
        res = await res.json()
        return res;
    } catch (err) {
        console.log(err);
    }
}

export async function sendRpmMail(data) {
    let token = sessionStorage.getItem('token')
    try {
        let res = await fetch(Urls.SEND_RPM_EMAIL, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Accept": "*",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        res = await res.json()
        return res;
    } catch (err) {
        console.log(err);
    }
}