import React, { useEffect, useState } from 'react'
import Topbar from '../../components/topBar/Topbar'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'
import useTitle from '../../hooks/useTitle';
import { useForm } from 'react-hook-form';
import { sendMail } from '../../webservices/getway';
import { toast } from 'react-toastify';
import { RoutePath } from '../../RoutePath';

export default function LandingPage() {

    const [loading, setLoading] = useState(false)
    const title = useTitle()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        title("investment advisor")
    }, [])

    const sendEmail = async (data) => {
        setLoading(true);

        let res = await sendMail(data)

        if (res && res.status) {
            toast.success(res.message)
            setLoading(false);
            reset()
            return;
        }
        setLoading(false)
        return toast.error(res && res.message)
    };

    return (
        <>
            <Topbar />
            {/* navbar start */}
            <nav className="navbar navbar-expand-lg bg-white px-5 py-3 py-lg-0">
                <Link
                    className="navbar-brand p-0"
                    to="/"
                >
                    <img
                        alt="logo"
                        className="logo"
                        src="./img/logo.jpg"
                    />
                </Link>
                <button
                    data-bs-toggle="collapse"
                    className="navbar-toggler"
                    data-bs-target="#navbarCollapse"
                    type="button"
                >
                    <span className="navbar-toggler-icon" />
                </button>
            </nav >
            {/* end */}

            {/* main section */}

            <div
                className="container-fluid p-0 mb-3"
                style={{
                    backgroundImage: `url(${"'"}${window.location.origin}/img/bull2.jpg${"'"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <div className="container p-3">
                    <div className="row justify-content-evenly">
                        <div className="col-lg-6 col-md-6 col-12 mt-5">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3 mt-5">
                                    <h4 className="mb-3 text-light">
                                        Muhurat-Investing
                                    </h4>
                                    <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                                        achive your ficancial goal with sebi registered investment advisor
                                    </h5>
                                    <h5 className="text-light">
                                        by using equity & govement sequirity
                                    </h5>
                                    <h4 className="mt-4 text-light rounded-1 p-2">
                                        SEBI Registration Number:INA000018692
                                    </h4>
                                    <h4 className="mt-4 text-light rounded-1 p-2">
                                        Advertisement Application No : 15835
                                    </h4>
                                    <div className="mt-5">
                                        <Link
                                            className="btn btn-primary py-md-3 px-md-5 buttons animated slideInRight"
                                            to="#"
                                        >
                                            Contact Us
                                        </Link>
                                        <Link
                                            className="btn btn-success ms-4 py-md-3 px-md-5 buttons animated slideInRight"
                                            to="https://api.whatsapp.com/send?phone=9131628434"
                                        >
                                            whatsapp Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-xl-6 col-lg-6 col-md-6 col-12 mt-5 wow slideInUp"
                            data-wow-delay="0.3s"
                        >
                            <div className="row justify-content-center align-items-center">
                                <div
                                    className="col-12 wow zoomIn mt-4"
                                    data-wow-delay="0.3s"
                                >
                                    <form onSubmit={handleSubmit(sendEmail)}>
                                        <div className="col-12 wow zoomIn mt-4" data-wow-delay="0.3s">
                                            <div className="d-flex flex-column p-5 rounded-3" style={{ backdropFilter: "blur(10px)" }}>
                                                <h3 className="text-light mb-3 text-center">Register Now</h3>
                                                <div
                                                    className="date mb-3"
                                                    id="date"
                                                    data-target-input="nearest"
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control border-0"
                                                        placeholder="Full Name"
                                                        style={{ height: "40px" }}
                                                        name="fullname"
                                                        {...register('fullname', { required: "full name is required" })}
                                                    />
                                                    {errors.fullname && <p className="text-danger">fullname is required.</p>}
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 mt-4"
                                                        placeholder="Email Address"
                                                        style={{ height: "40px" }}
                                                        name="email"
                                                        {...register('email', { required: "email is required" })}
                                                    />
                                                    {errors.email && <p className="text-danger">email is required.</p>}
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 mt-4"
                                                        placeholder="Mobile Number"
                                                        style={{ height: "40px" }}
                                                        name="number"
                                                        {...register('number', { required: "number is required" })}
                                                    />
                                                    {errors.number && <p className="text-danger">Mob number is required.</p>}
                                                    <select
                                                        type="text"
                                                        className="form-control border-0 mt-4 bg-white"
                                                        style={{ height: "40px" }}
                                                        name="service"
                                                        {...register('service', { required: "service is required" })}
                                                    >
                                                        <option value="">-Services-</option>
                                                        <option value="Stock cash">Stock cash</option>
                                                        <option value="HNI Cash">HNI Cash</option>
                                                        <option value="Stock Future">Stock Future</option>
                                                        <option value="HNI Future">HNI Future</option>
                                                        <option value="Stock Option">Stock Option</option>
                                                        <option value="HNI option">HNI option</option>
                                                        <option value="Index Future">Index Future</option>
                                                        <option value="Index Option">Index Option</option>
                                                        <option value="BTST">BTST</option>
                                                        <option value="Short term investment">Short term investment</option>
                                                        <option value="Long term investment">Long term investment</option>

                                                    </select>
                                                    {errors.service && <p className="text-danger">service is required.</p>}
                                                </div>

                                                <div className="custom-control custom-checkbox pt-2">
                                                    <input
                                                        className="form-control-input"
                                                        id="customCheck1"
                                                        type="checkbox"
                                                        defaultValue={1}
                                                        name="checkbox"
                                                        defaultChecked="checked"
                                                    />&nbsp;
                                                    <label className="text-light" htmlFor="customCheck1">
                                                        I agree to all &nbsp;
                                                        <Link to={RoutePath.DISCLAIMER} className="text-dark">
                                                            Terms &amp; Conditions
                                                        </Link>
                                                    </label>
                                                    <label className="text-dark mt-2 font-weight-bold text-capitalize"><span className="text-danger" style={{ fontSize: "20px" }}>*</span>Investments in securities market are subject to market risks. Read all the related documents carefully before investing.</label>
                                                    <label className="text-dark mt-2 mb-2 font-weight-bold text-capitalize"><span className="text-danger" style={{ fontSize: "20px" }}>*</span>Registration granted by SEBI and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.</label>
                                                </div>
                                                <button type="submit" className="btn btn-dark buttons">
                                                    {loading ? <div class="spinner-border text-primary" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div> : "Submit"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            {/* Our Mission start */}
            <div className="container-fluid py-1 fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 d-flex justify-content-center">
                            <img className="w-50 zoomIn wow" data-wow-delay="0.9s" src="img/our-mission.png" alt='img3' />
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title mb-4 zoomIn wow mt-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Mission</h5>
                            </div>
                            <p className="mb-4 zoomIn wow">Our mission is to empower our clients with the knowledge and strategies
                                needed to
                                build and preserve wealth. We are dedicated to providing personalized financial advice,
                                fostering long-term relationships, and delivering exceptional value through our expertise and
                                integrity.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Our Mission end */}
            {/* end  */}
            < Footer />
        </>
    )
}
