import { Link } from "react-router-dom";
import * as COMPONENT from "../components/ComponentRoute"
import useTitle from "../hooks/useTitle";
import { useEffect } from "react";


export default function BankDetails() {
    const title = useTitle()

    useEffect(() => {
        title("Bank Details")
    }, [])
    return (
        <>
        <COMPONENT.HERO_COMPONENT name={"Bank Details"} bg={"bg-bank"}/>
            <section className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" >
                {/* Nav tabs start */}
                <h1 className="text-center">Our Bank Details</h1>
                <hr />
                <ul className="nav nav-pills nav-justified d-flex justify-content-center">
                    <li>
                        <Link
                            className="nav-link tabs active"
                            data-bs-toggle="tab"
                            data-bs-target="#sbi"
                        >
                            <img src={`${window.location.origin}/img/hdfclogo.png`} alt="hdfc" height={100} width={100} />
                        </Link>
                    </li>
                    <li >
                        <Link
                            className="nav-link tabs"
                            data-bs-toggle="tab"
                            data-bs-target="#hdfc"
                        >
                            <img src={`${window.location.origin}/img/sbilogo.png`} alt="sbi" height={100} width={100} />
                        </Link>
                    </li>
                </ul>
                {/* Nav tabs end */}
                <div className="tab-content mt-5">
                    <div id="sbi" className="container tab-pane animated slideInLeft bg-white rounded shadow p-3 active" >
                        <br />
                        <img className="ms-5" src={`${window.location.origin}/img/hdfclogo.png`} alt="hdfc" height={100} width={100} />
                        <h3 className="ms-4 mb-4">HDFC Bank</h3>
                        <div className="container">
                            <div className="row justify-content-evenly align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6 text-center">
                                    <div className="row justify-content-center">
                                        <h2 className="ms-4">Account Details</h2>
                                        <ul className="mt-3" style={{ listStyle: "none" }}>
                                            <li>Bank Name : HDFC Bank</li>
                                            <li>Account Name : Muhurat Investing</li>
                                            <li>Account No. : 50200089850851</li>
                                            <li>IFSC code : HDFC0009403</li>
                                            <li>Account-Type : Current Account</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6 text-center">
                                    <h2 className="ms-4">Pay Using UPI</h2>
                                    <h4>BHIM UPI / PHONEPE / GOOGLE PAY / PAYTM</h4>
                                    <div className="h-auto w-100 mt-5">
                                        <img className="QR-size" src={`${window.location.origin}/img/PaymentQR.jpg`} alt="QR" />
                                        <h4 className="mt-3">UPI ID : 9584096836@axl</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="hdfc" className="container bg-white tab-pane animated p-3 slideInLeft rounded shadow" style={{ color: "black !important" }}>
                        <br />
                        <img className="ms-4" src={`${window.location.origin}/img/sbilogo.png`} alt="sbi" height={100} width={100} />
                        <h3 className="ms-3 mb-4">SBI Bank</h3>
                        <div className="container">
                            <div className="row justify-content-evenly align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6 text-center">
                                    <div className="row justify-content-center">
                                        <h2 className="ms-4">Account Details</h2>
                                        <ul className="mt-3" style={{ listStyle: "none" }}>
                                            <li>Bank Name : State Bank Of India</li>
                                            <li>Account Name : Muhurat Investing</li>
                                            <li>Account No. : 42603574208</li>
                                            <li>IFSC code : SBIN0004037</li>
                                            <li>Account-Type : Current Account</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6 text-center">
                                    <h2 className="ms-4">Pay Using UPI</h2>
                                    <h4>BHIM UPI / PHONEPE / GOOGLE PAY / PAYTM</h4>
                                    <div className="h-auto w-100 mt-5">
                                        <img className="QR-size" src={`${window.location.origin}/img/PaymentQR.jpg`} alt="QR" />
                                        <h4 className="mt-3">UPI ID : 9584096836@axl</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

